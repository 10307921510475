export default {
  methods: {
    priceFormat (prix) {
      const val = parseFloat(Math.round(prix * 100) / 100).toFixed(2)
      if (this.$i18n.locale.includes('fr')) {
        return val.toString().replace('.', ',') + ' $'
      } else {
        return '$' + val
      }
    }
  }
}
